import { ActionTree, GetterTree, MutationTree } from 'vuex'
import axios from 'axios'
import type {
  EmailForm,
  MarketingPermissions,
  OrderListStatus,
  PasswordForm,
  State,
  UserAddress,
  UserDetails,
  UserOrder,
  Wishlist
} from '~/types/user'
import type { WishlistItem } from '~/types/item'
import { RootState } from '~/store'
import { ToastActions } from '~/store/toast'
import { CartActions } from '~/store/cart'
import { useAuth, useFirestore, useReauth } from '~/composable/useFirebase'
import { ProductAlgolia } from '~/types/product'
import { getVariationImage, formatItemVersion } from '~/helpers/product'
import { getProductId } from '~/helpers/customization'

export const state = () => ({
  loggedIn: false,
  user: null,
  details: null,
  orders: [],
  currentUserOrder: null,
  orderListStatus: 'initial'
})

export type UserState = State

interface AuthUser {
  uid: string
  email: string
}

export enum UserActions {
  onAuthStateChanged = 'user/onAuthStateChanged',
  initializeLogin = 'user/initLogin',
  setLoggedInStatus = 'user/setLoggedInStatus',
  setUserDetails = 'user/setUserDetails',
  clearState = 'user/clearState',
  updateUserDetails = 'user/updateUserDetails',
  sendLoginEmailUpdateVerification = 'user/sendLoginEmailUpdateVerification',
  updateLoginPassword = 'user/updateLoginPassword',
  updateAddress = 'user/updateAddress',
  addAddress = 'user/addAddress',
  setCurrentAddress = 'user/setCurrentAddress',
  removeAddress = 'user/removeAddress',
  fetchUserOrders = 'user/fetchUserOrders',
  updateUserMarketingPermissions = 'user/updateUserMarketingPermissions',
  setCurrentUserOrder = 'user/setCurrentUserOrder',
  removeWishlist = 'user/removeWishlist',
  renameWishlist = 'user/renameWishlist',
  createWishlist = 'user/createWishlist',
  clearWishlist = 'user/clearWishlist',
  addItemToWishlist = 'user/addItemToWishlist',
  removeItemFromWishlist = 'user/removeItemFromWishlist',
  updateItemInWishlists = 'user/updateItemInWishlists',
}

export enum UserMutations {
  setLoggedInStatus = 'user/SET_LOGGED_IN_STATUS',
  setUid = 'user/SET_UID',
  setDetails = 'user/SET_DETAILS',
  setFirstname = 'user/SET_FIRSTNAME',
  setLastname = 'user/SET_LASTNAME',
  setAddresses = 'user/SET_ADDRESSES',
  setZip = 'user/SET_ZIP',
  setPhone = 'user/SET_PHONE',
  setCity = 'user/SET_CITY',
  setMarketingPermissions = 'user/SET_MARKETING_PERMISSIONS',
  setWishlistLoading = 'user/SET_WISHLIST_LOADING',
}

export enum UserGetters {
  loggedIn = 'user/loggedIn',
  uid = 'user/uid',
  details = 'user/details',
  firstname = 'user/firstname',
  lastname = 'user/lastname',
  initials = 'user/initials',
  addresses = 'user/addresses',
  city = 'user/city',
  zip = 'user/zip',
  phone = 'user/phone',
  email = 'user/email',
  marketingPermissions = 'user/marketingPermissions',
  isEmptyAddressBook = 'user/isEmptyAddressBook',
  defaultBillingAddress = 'user/defaultBillingAddress',
  defaultShippingAddress = 'user/defaultShippingAddress',
  addressById = 'user/addressById',
  userOrders = 'user/userOrders',
  userOrderByOrderNumber = 'user/userOrderByOrderNumber',
  userOrdersLoaded = 'user/userOrdersLoaded',
  orderListStatus = 'user/orderListStatus',
  currentUserOrder = 'user/currentUserOrder',
  wishlists = 'user/wishlists',
  wishlistById = 'user/wishlistById',
  totalWishlistItemAmount = 'user/totalWishlistItemAmount',
  wishlistsContainOutdatedItems = 'user/wishlistsContainOutdatedItems'
}

export const actions: ActionTree<UserState, RootState> = {
  setUserDetails ({ commit }, payload: UserDetails) {
    let details = null
    if (payload) {
      details = {
        firstname: payload.firstname,
        lastname: payload.lastname,
        email: payload.email,
        phone: payload.phone,
        addresses: payload.addresses,
        marketingPermissions: payload.marketingPermissions,
        wishlists: payload.wishlists
      }
    }
    commit('SET_DETAILS', details)
  },
  async updateUserDetails ({ dispatch, state }, payload: UserDetails): Promise<boolean> {
    const updatedDetails = { ...state.details, ...payload }
    dispatch('setUserDetails', updatedDetails)
    const { updateUserDocument } = useFirestore(this.$fire)

    // Update Firestore
    const isUpdateSuccess = await updateUserDocument(updatedDetails)

    if (isUpdateSuccess) {
      // Update CRM (Firestore & CDP updates should be done by single callable function instead)
      const updateCustomerDataPlatform = this.$fire.functions.httpsCallable('veke3000-customer-updateCustomerDataPlatform')
      updateCustomerDataPlatform({ ...updatedDetails, email: this.$fire.auth.currentUser?.email })
        .catch((updateCustomerDataPlatformError: any) => {
          console.error('CDP update failed', updateCustomerDataPlatformError)
          // Sentry.captureException(updateCustomerDataPlatformError)
          return false
        })

      dispatch(ToastActions.addToast, { message: 'Tietosi päivitettiin', type: 'success', ttl: 3000 }, { root: true })
      return true
    } else {
      return false
    }
  },
  async sendLoginEmailUpdateVerification ({ dispatch }, payload: EmailForm) {
    const { verifyBeforeUpdateEmail } = useAuth(this.$fire)
    const { reauthenticateUser } = useReauth(this.$fire, this.$fireModule)

    const updateAuthEmail = async (psw: string, email: string): Promise<boolean> => {
      return await reauthenticateUser(psw)
        .then(async (success: boolean) => {
          if (success) {
            return await verifyBeforeUpdateEmail(email)
          } else {
            dispatch(UserActions.clearState)
            return false
          }
        })
    }

    return await updateAuthEmail(payload.password, payload.email)
      .then((success: boolean) => {
        if (success) {
          dispatch(ToastActions.addToast, { message: `Sait sähköpostia osoitteeseen ${payload.email}!`, type: 'success', ttl: 3000 }, { root: true })
          return true
        } else {
          dispatch(ToastActions.addToast, { message: 'Tapahtui virhe! Tarkasta salasanasi.', type: 'error', ttl: 3000 }, { root: true })
          return false
        }
      })
  },
  async updateLoginPassword ({ dispatch }, payload: PasswordForm): Promise<boolean> {
    const { updatePassword } = useAuth(this.$fire)
    const { reauthenticateUser } = useReauth(this.$fire, this.$fireModule)

    const updateAuthPassword = async (currentPsw: string, newPsw: string): Promise<boolean> => {
      return await reauthenticateUser(currentPsw)
        .then(async (success: boolean) => {
          if (success) {
            return await updatePassword(newPsw)
          } else {
            dispatch(UserActions.clearState)
            return false
          }
        })
    }

    return await updateAuthPassword(payload.oldPassword, payload.password)
      .then((success: boolean) => {
        if (success) {
          dispatch(ToastActions.addToast, { message: 'Salasanasi on vaihdettu!', type: 'success', ttl: 3000 }, { root: true })
          return true
        } else {
          dispatch(ToastActions.addToast, { message: 'Tapahtui virhe! Tarkasta salasanasi.', type: 'error', ttl: 3000 }, { root: true })
          return false
        }
      })
  },
  async addAddress ({ dispatch, commit }, payload): Promise<boolean> {
    const { addAddress } = useFirestore(this.$fire)
    const address = { ...payload }

    return await addAddress(address)
      .then(({ id }: { id: string }) => {
        address.id = id
        commit('ADD_ADDRESS', address)
        dispatch(ToastActions.addToast, { message: 'Osoite lisätty!', type: 'success', ttl: 3000 }, { root: true })
        return true
      })
      .catch((err: any) => {
        // Sentry.captureException(err)
        console.error(err)
        return false
      })
  },
  async updateAddress ({ dispatch, commit }, payload: UserAddress): Promise<boolean> {
    const { updateAddress } = useFirestore(this.$fire)

    return await updateAddress(payload).then(() => {
      commit('UPDATE_ADDRESS', payload)
      dispatch(ToastActions.addToast, { message: 'Osoite päivitetty!', type: 'success', ttl: 3000 }, { root: true })
      return true
    })
      .catch((err: any) => {
        // Sentry.captureException(err)
        console.error(err)
        return false
      })
  },
  setCurrentAddress ({ commit }, payload: UserAddress): void {
    commit('SET_CURRENT_ADDRESS', payload)
  },
  async removeAddress ({ dispatch, commit }, payload: string): Promise<boolean> {
    const { removeAddress } = useFirestore(this.$fire)

    return await removeAddress(payload).then(() => {
      commit('REMOVE_ADDRESS', payload)
      dispatch(ToastActions.addToast, { message: 'Osoite poistettu!', type: 'success', ttl: 3000 }, { root: true })
      return true
    })
      .catch((err: any) => {
        // Sentry.captureException(err)
        dispatch(ToastActions.addToast, { message: 'Osoitteen poisto epäonnistui! Yritä uudelleen.', type: 'error', ttl: 3000 }, { root: true })
        console.error(err)
        return false
      })
  },
  onAuthStateChanged: ({ commit }, { authUser }) => {
    if (!authUser) {
      commit('SET_USER', null)
    } else {
      commit('SET_USER', authUser)
    }
  },
  setLoggedInStatus ({ commit, dispatch }, payload: boolean) {
    commit('SET_LOGGED_IN_STATUS', payload)
    // Reload cart rules after login-status changes
    dispatch(CartActions.fetchCartRule, null, { root: true })
  },
  clearState ({ commit }) {
    commit('CLEAR_STATE')
  },
  async fetchUserOrders ({ commit }): Promise<void> {
    const { getUserOrders } = useFirestore(this.$fire)
    // initialize orders
    commit('ADD_USER_ORDERS', [])
    commit('SET_ORDER_LIST_STATUS', 'fetching')
    return await getUserOrders()
      .then((response: any) => {
        if (response.orders) {
          commit('ADD_USER_ORDERS', response.orders)
          commit('SET_ORDER_LIST_STATUS', 'fetched')
        } else {
          commit('SET_ORDER_LIST_STATUS', 'error')
        }
      })
      .catch((err: any) => {
        // Sentry.captureException(err)
        console.error(err)
        commit('SET_ORDER_LIST_STATUS', 'error')
      })
  },
  removeUserOrders ({ commit }) {
    commit('REMOVE_USER_ORDERS', null)
  },
  setCurrentUserOrder ({ state, commit }, payload: string) {
    const currentUserOrder = state.orders?.find(order => order.order.order_number === payload)
    commit('SET_CURRENT_USER_ORDER', currentUserOrder)
  },
  async updateUserMarketingPermissions ({ dispatch, commit }, payload: MarketingPermissions): Promise<boolean> {
    const { updateUserMarketingPermissions } = useFirestore(this.$fire)

    await updateUserMarketingPermissions(payload)
      .then(() => {
        commit('SET_MARKETING_PERMISSIONS_SMS', payload.sms)
        commit('SET_MARKETING_PERMISSIONS_EMAIL', payload.email)
        dispatch(ToastActions.addToast, { message: 'Markkinointilupasi on päivitetty!', type: 'success', ttl: 3000 }, { root: true })

        // This call is handled in server middleware (marketing-permissions-update)
        axios.post('/api/marketing-permissions-update', { email: this.$fire.auth.currentUser?.email, marketingPermissions: payload })
          .catch((err: any) => {
            // Sentry.captureException(updateCustomerDataPlatformError)
            console.error('updateCustomerDataPlatform', err)
            return false
          })
      })
      .catch((err: any) => {
        // Sentry.captureException(err)
        console.error(err)
        dispatch(ToastActions.addToast, { message: 'Voi ei, jotain meni pieleen. Yritä uudelleen.', type: 'error', ttl: 3000 }, { root: true })
        return false
      })

    return true
  },
  async createWishlist ({ dispatch, commit }, payload: { name: string }): Promise<Wishlist | null> {
    const { addWishlist } = useFirestore(this.$fire)
    // Create wishlist and add to Firestore
    const wishlist = await addWishlist(payload?.name)
    if (wishlist) {
      // Add created wishlist to state
      commit('ADD_WISHLIST', wishlist)
      // Celebrate success
      dispatch(ToastActions.addToast, {
        message: `Toivelista "${wishlist.name}" luotu!`,
        type: 'success',
        ttl: 5000,
        cta: { label: 'Siirry toivelistalle', to: `/oma-tili/toivelistat/${wishlist.id}` }
      }, { root: true })
    } else {
      // Sound alarm on error
      dispatch(ToastActions.addToast, { message: 'Uuden toivelistan luonti epäonnistui!', ttl: 3000, type: 'error' }, { root: true })
    }

    return wishlist
  },
  async removeWishlist ({ state, dispatch, commit }, wishlist: Wishlist) {
    const { deleteWishlist } = useFirestore(this.$fire)
    await deleteWishlist(wishlist.id)
      .then(() => {
        commit('REMOVE_WISHLIST', wishlist.id)
        dispatch(ToastActions.addToast, {
          message: `Toivelista "${wishlist.name}" poistettu!`,
          type: 'success',
          ttl: 3000
        }, { root: true })
        /**
         * Bloomreach remove-event
         */
        if (window.exponea !== undefined) {
          window.exponea.identify(state.details.email.toLowerCase().trim())
          window.exponea.track('wishlist_update', {
            action: 'remove',
            id: wishlist.id,
            product_list: []
          })
        }
      })
      .catch((e) => {
        console.error(e)
        dispatch(ToastActions.addToast, { message: 'Toivelistan poistaminen epäonnistui!', ttl: 3000, type: 'error' }, { root: true })
      })
  },
  async renameWishlist ({ dispatch, state }, payload: { wishlistId: string, name: string }) {
    const { updateWishlist } = useFirestore(this.$fire)
    const wishlist = state.details?.wishlists?.find((wl: Wishlist) => wl.id === payload.wishlistId) || null
    if (wishlist) {
      // Update wishlist name to state
      wishlist.name = payload.name
      // Update wishlist name to Firestore
      await updateWishlist(wishlist)
        .then(() => {
          dispatch(ToastActions.addToast, {
            message: 'Toivelistan nimi päivitetty!',
            type: 'success',
            ttl: 3000
          }, { root: true })
        })
        .catch(() => dispatch(ToastActions.addToast, { message: 'Toivelistan päivittäminen epäonnistui!', ttl: 3000, type: 'error' }, { root: true }))
    }
  },
  async addItemToWishlist ({ state, dispatch, commit, getters }, payload: { wishlistId?: string, product: ProductAlgolia }) {
    // Load or create a new wishlist
    const wishlist: Wishlist|undefined = (!payload.wishlistId) ? await dispatch('createWishlist', 'Toivelista') : getters.wishlistById(payload.wishlistId)

    if (!wishlist) {
      dispatch(ToastActions.addToast, {
        message: 'Toivelistaa ei löytynyt!',
        type: 'error',
        ttl: 3000
      }, { root: true })
      return
    }

    // Convert given product to a wishlist item and add to the wishlist
    commit('ADD_ITEM_TO_WISHLIST', { wishlistId: wishlist.id, product: payload.product })

    this.app.$matomo.trackEvent('wishlist', 'addItem', `${payload.product.sku}`)
    /**
     * Bloomreach add-event
     */
    if (window.exponea !== undefined) {
      window.exponea.identify(state.details.email.toLowerCase().trim())
      window.exponea.track('wishlist_update', {
        action: 'add',
        id: wishlist.id,
        variant_id: payload.product.sku,
        product_list: wishlist.items.map((item) => { return { variant_id: item.sku } })
      })
    }

    const { updateWishlist } = useFirestore(this.$fire)
    // Update wishlist to Firestore
    await updateWishlist(wishlist)
      .then(() => {
        dispatch(ToastActions.addToast, {
          message: `Tuote lisätty toivelistalle "${wishlist.name}"`,
          type: 'success',
          ttl: 5000,
          cta: { label: 'Siirry toivelistalle', to: `/oma-tili/toivelistat/${wishlist.id}` }
        }, { root: true })
      })
      .catch(() => dispatch(ToastActions.addToast, { message: 'Tuotteen lisääminen toivelistaan epäonnistui', ttl: 3000, type: 'error' }, { root: true }))
  },
  async clearWishlist ({ state, commit, getters, dispatch }, wishlistId: string) {
    // Update Firestore by calling useFirebase.updateWishlist(wishlist: Wishlist)-function
    const wishlist: Wishlist|undefined = getters.wishlistById(wishlistId)
    if (wishlist) {
      // Remove all items from the wishlist
      commit('CLEAR_WISHLIST', wishlistId)
      const { updateWishlist } = useFirestore(this.$fire)
      /**
       * Bloomreach remove-event
       */
      if (window.exponea !== undefined) {
        window.exponea.identify(state.details.email.toLowerCase().trim())
        window.exponea.track('wishlist_update', {
          action: 'remove',
          id: wishlist.id,
          product_list: []
        })
      }

      // Update wishlist to Firestore
      await updateWishlist(wishlist)
        .then(() => {
          dispatch(ToastActions.addToast, {
            message: `Toivelista "${wishlist.name}" tyhjennetty!`,
            type: 'success',
            ttl: 3000
          }, { root: true })
        })
        .catch(() => dispatch(ToastActions.addToast, { message: 'Toivelistan tyhjennys epäonnistui', ttl: 3000, type: 'error' }, { root: true }))
    }
  },
  async removeItemFromWishlist ({ state, commit, getters, dispatch }, payload: { wishlistId: string, item: WishlistItem }) {
    const wishlist: Wishlist|undefined = getters.wishlistById(payload.wishlistId)
    if (!wishlist) {
      dispatch(ToastActions.addToast, {
        message: 'Toivelistaa ei löytynyt!',
        type: 'error',
        ttl: 3000
      }, { root: true })
      return
    }

    // Remove the given item from the wishlist
    commit('REMOVE_ITEM_FROM_WISHLIST', payload)
    // Update Firestore by calling useFirebase.updateWishlist(wishlist: Wishlist)-function
    const { updateWishlist } = useFirestore(this.$fire)

    this.app.$matomo.trackEvent('wishlist', 'removeItem', `${payload.item.sku}`)
    /**
     * Bloomreach remove-event
     */
    if (window.exponea !== undefined) {
      window.exponea.identify(state.details.email.toLowerCase().trim())
      window.exponea.track('wishlist_update', {
        action: 'remove',
        id: wishlist.id,
        product_list: wishlist.items.map((item) => { return { variant_id: item.sku } })
      })
    }

    // Update wishlist to Firestore
    await updateWishlist(wishlist)
      .then(() => {
        dispatch(ToastActions.addToast, {
          message: 'Tuote poistettu toivelistalta!',
          type: 'success',
          ttl: 3000
        }, { root: true })
      })
      .catch(() => dispatch(ToastActions.addToast, { message: 'Tuotteen poistaminen toivelistasta epäonnistui', ttl: 3000, type: 'error' }, { root: true }))
  },
  async updateItemInWishlists ({ commit, dispatch, state }, payload: { wishlistIds: string[], product: ProductAlgolia }): Promise<boolean> {
    const { updateWishlist } = useFirestore(this.$fire)
    let hasErrors = false
    let updatedListCount = 0

    // Loop through all wishlists in state
    for (const wishlist of state.details.wishlists) {
      const shouldItemBeAddedOnCurrentWishlist = payload.wishlistIds.includes(wishlist.id)
      // Primarily find wishlist item by customization ID and secondarily by product SKU
      const item = wishlist.items.find(item => item.id === getProductId(payload.product))

      if (shouldItemBeAddedOnCurrentWishlist && !item) {
        // Add the given item on a wishlist if wishlist ID is in wishlistIds-array and item is NOT already on the wishlist
        commit('ADD_ITEM_TO_WISHLIST', { wishlistId: wishlist.id, product: payload.product })
        await updateWishlist(wishlist).catch(() => hasErrors = true)
        updatedListCount++
      } else if (!shouldItemBeAddedOnCurrentWishlist && item) {
        // Remove the given item from a wishlist if wishlist ID not in wishlistIds-array AND wishlist contains the item
        commit('REMOVE_ITEM_FROM_WISHLIST', { wishlistId: wishlist.id, item })
        await updateWishlist(wishlist).catch(() => hasErrors = true)
        updatedListCount++
      }
    }

    if (hasErrors) {
      dispatch(ToastActions.addToast, { message: 'Toivelistojen päivitys epäonnistui!', ttl: 3000, type: 'error' }, { root: true })
      return false
    } else {
      const message = updatedListCount > 1 ? `${updatedListCount} toivelistaa päivitetty!` : 'Toivelista päivitetty!'
      dispatch(ToastActions.addToast, {
        message,
        type: 'success',
        ttl: 3000
      }, { root: true })
      return true
    }
  }
}

export const mutations: MutationTree<UserState> = {
  CLEAR_STATE (state: any) {
    state.loggedIn = false
    state.user = null
    state.details = null
    state.orders = null
    state.orderListStatus = 'initial'
  },
  SET_LOGGED_IN_STATUS (state: any, payload: boolean) {
    state.loggedIn = payload
  },
  // @ts-ignore
  SET_USER: (state: any, payload: AuthUser | null) => {
    if (payload) {
      const { uid, email } = payload
      state.user = { uid, email }
    } else {
      state.user = null
    }
  },
  SET_DETAILS (state, payload: UserDetails) {
    state.details = payload
  },
  SET_FIRSTNAME (state, payload: string) {
    state.details.firstname = payload
  },
  SET_LASTNAME (state, payload: string) {
    state.details.lastname = payload
  },
  SET_ADDRESSES (state, payload: UserAddress[]) {
    state.details.addresses = payload
  },
  SET_PHONE (state, payload: string) {
    state.details.phone = payload
  },
  SET_MARKETING_PERMISSIONS (state, payload: MarketingPermissions) {
    state.details.marketingPermissions = payload
  },
  ADD_ADDRESS (state, payload: UserAddress) {
    state.details.addresses.push(payload)
  },
  REMOVE_ADDRESS (state, payload: string) {
    state.details.addresses = state.details.addresses.filter((address: UserAddress) => address.id !== payload)
  },
  UPDATE_ADDRESS (state, payload: UserAddress) {
    // const addressIndex = state.details.addresses.findIndex((address: UserAddress) => address.id === payload.id)
    // state.details.addresses[addressIndex] = payload
    state.details.addresses = [...state.details.addresses.map(a => a.id !== payload.id ? a : { ...a, ...payload })]
  },
  SET_CURRENT_ADDRESS (state, payload: UserAddress): void {
    state.currentAddress = payload
  },
  ADD_USER_ORDERS (state, payload: UserOrder[]) {
    state.orders = payload
  },
  REMOVE_USER_ORDERS (state) {
    state.orders = []
  },
  SET_ORDER_LIST_STATUS (state, payload: OrderListStatus): void {
    state.orderListStatus = payload
  },
  SET_MARKETING_PERMISSIONS_EMAIL (state, payload: boolean): void {
    state.details.marketingPermissions.email = payload
  },
  SET_MARKETING_PERMISSIONS_SMS (state, payload: boolean): void {
    state.details.marketingPermissions.sms = payload
  },
  SET_CURRENT_USER_ORDER (state, payload: UserOrder) {
    state.currentUserOrder = payload
  },
  ADD_WISHLIST (state, payload: Wishlist) {
    state.details?.wishlists.push(payload)
  },
  REMOVE_WISHLIST (state, payload: string) {
    const i = state.details?.wishlists?.map(wishlist => wishlist.id).indexOf(payload)
    if (i >= 0) {
      state.details?.wishlists?.splice(i, 1)
    }
  },
  ADD_ITEM_TO_WISHLIST (state, payload: { wishlistId: string, product: ProductAlgolia }) {
    const wishlist = state.details.wishlists.find(wl => wl.id === payload.wishlistId)
    if (wishlist) {
      const wishlistItem: WishlistItem = {
        id: getProductId(payload.product),
        // @ts-ignore
        version: formatItemVersion(this.$config.ITEM_VERSION_WISHLISTITEM),
        baseType: 'wishlistItem',
        sku: payload.product.sku,
        amount: 1,
        title: payload.product.name.default,
        image: getVariationImage(payload.product, 'small'),
        slug: payload.product.slug
      }

      if (payload.product.customization) {
        wishlistItem.customization = payload.product.customization
      }
      wishlist.items.push(wishlistItem)
    }
  },
  UPDATE_WISHLIST_ITEM (state, payload: { wishlistId: string, item: WishlistItem }) {
    const wishlist = state.details.wishlists.find((wl: Wishlist) => wl.id === payload.wishlistId) || null
    if (wishlist) {
      wishlist.items = [...wishlist.items.map(item => item.id !== payload.item.id ? item : { ...item, ...payload.item })]
    }
  },
  REMOVE_ITEM_FROM_WISHLIST (state, payload: { wishlistId: string, item: WishlistItem }) {
    const wishlist = state.details.wishlists.find(wl => wl.id === payload.wishlistId)
    if (wishlist) {
      wishlist.items = wishlist.items.filter(item => item.id !== payload.item.id) || []
    }
  },
  CLEAR_WISHLIST (state, wishlistId: string) {
    const wishlist = state.details.wishlists.find(wl => wl.id === wishlistId)
    if (wishlist) {
      wishlist.items = []
    }
  },
  UPDATE_WISHLIST (state, payload: Wishlist) {
    state.details.wishlists = state.details.wishlists.map(wl => payload.id === wl.id ? payload : wl)
  }
}

export const getters: GetterTree<UserState, RootState> = {
  loggedIn: (state: any): boolean => state.loggedIn,
  user: (state): any => state.user,
  uid: (state: any): string => state.user?.uid,
  details: (state): UserDetails | null => {
    if (!process.server) {
      return state.details
    }
    return null
  },
  firstname: (state): string => state.details?.firstname,
  lastname: (state): string => state.details?.lastname,
  initials: (state): string => (state.details?.firstname?.charAt(0) || '') + (state.details?.lastname?.charAt(0) || ''),
  addresses: (state): UserAddress[] => state.details?.addresses,
  phone: (state): string | null => state.details?.phone,
  marketingPermissions: (state): MarketingPermissions => state.details?.marketingPermissions,
  isEmptyAddressBook: (state): boolean => (state.details?.addresses ? (state.details?.addresses.length === 0) : true),
  defaultBillingAddress: (state): UserAddress | null => state.details?.addresses?.find(address => address.defaultBilling === true) || null,
  defaultShippingAddress: (state): UserAddress | null => state.details?.addresses?.find(address => address.defaultShipping === true) || null,
  addressById: state => (id: string): UserAddress | null => state.details?.addresses?.find(address => address.id === id) || null,
  userOrders: (state): UserOrder[] => state.orders,
  userOrderByOrderNumber: state => (orderNumber: string): UserOrder | undefined => state.orders.find(order => order.order.order_number === orderNumber),
  userOrdersLoaded: (state): boolean => state.orders !== null,
  orderListStatus: (state): OrderListStatus => state.orderListStatus,
  currentUserOrder: (state): UserOrder | null => state.currentUserOrder,
  wishlists: (state): Wishlist[] => state.details?.wishlists,
  totalWishlistItemAmount: (state): number => state.details?.wishlists?.length ? state.details?.wishlists.reduce((total: number, wl: Wishlist) => total + wl.items.length, 0) : 0,
  wishlistById: state => (id: string): Wishlist | undefined => state.details?.wishlists?.find(wl => wl.id === id),
  wishlistsContainOutdatedItems: state => (version: number): boolean => state.details?.wishlists?.length ? state.details.wishlists.some((wl: Wishlist) => wl.items.some(item => (!item.version || item.version !== version))) : false
}
