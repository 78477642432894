import { CategoryGetters } from '~/store/category'
import { BreadcrumbActions } from '~/store/breadcrumb'
import type { Breadcrumb } from '~/types/breadcrumb'
import type { CategoryItem } from '~/types/category'

const breadcrumbNames = require('../config/breadcrumbNames.json')

export function useBreadcrumb () {
  const setBreadcrumbs = (route: any, store: any) => {
    const routePath = route.path?.replace(/^\/+/, '').replace(/\/+$/, '').split('/') || []
    let categoryPath: Breadcrumb[] | null = []
    const pageType = getPageType(route)

    // Prevent showing breadcrumbs on: HOME, CART, CHECKOUT, THANK-YOU, ETC...
    if (pageType === 'no-breadcrumbs') {
      categoryPath = null
    } else if (pageType === 'product') {
      // SKIP PRODUCT page handling: breadcrumb are populated on product page
      return
    } else if (pageType === 'blogi') {
      // HANDLE BLOG
      if (routePath[1]) { return } // Blog slugs are handled on blogi/_slug-page
      categoryPath.push({ slug: routePath[0], value: breadcrumbNames[routePath[0]] || routePath[0] })
    } else if (pageType === 'wishlist' && routePath[2]) {
      // HANDLE WISHLISTS
      return // Wishlist paths are handled on toivelistat/_id-page
    } else if (pageType === 'category') {
      // CATEGORY-pages
      if (routePath[0] === 'category' && routePath.length === 1) {
        categoryPath.push({ slug: 'category', value: 'Kaikki tuotteet' })
      }
      for (let a = 0; a < routePath.length; a++) {
        if (routePath[a] === 'category') { continue }
        const currentPath = routePath.slice().splice(0, a + 1).join('/')
        const cat: CategoryItem = store.getters[CategoryGetters.getCategoryByPath](currentPath)
        categoryPath.push({ slug: currentPath, value: cat?.name || '?' })
      }
    } else {
      for (let i = 0; i < routePath.length; i++) {
        const currentPath = routePath.slice().splice(0, i + 1).join('/')
        categoryPath.push({ slug: currentPath, value: breadcrumbNames[routePath[i]] || routePath[i] })
      }
    }

    store.dispatch(BreadcrumbActions.setCurrentBreadcrumb, categoryPath)
  }

  return {
    setBreadcrumbs
  }
}

const getPageType = (route: any) => {
  const campaignSubpagesWithoutCategory = ['paivatarjous', 'diy-kilpailu', 'taimi-kilpailu', 'ale-kilpailu']
  const page = route.path?.split('/') || []

  switch (page.length > 0 ? page[1] : null) {
    case '':
      return 'no-breadcrumbs'
    case 'kassa':
      return 'no-breadcrumbs'
    case 'kiitos':
      return 'no-breadcrumbs'
    case '_auth':
      return 'no-breadcrumbs'
    case 'category':
      return 'category'
    case 'tuotesarjat':
      return 'category'
    case 'brandit':
      return 'category'
    case 'uutuudet':
      return 'category'
    case 'tarjoukset':
      return (page[2] === 'black-friday' && page.length === 3) ? 'no-breadcrumbs' : campaignSubpagesWithoutCategory.includes(page[2]) ? 'other' : 'category'
    case 'oma-tili':
      return (page[2] === 'toivelistat' && page.length === 4) ? 'wishlist' : 'default'
    case 'outlet':
      return 'category'
    case 'product':
      return 'product'
    case 'blogi':
      return 'blogi'
    default:
      return 'other'
  }
}
