import { GetterTree, ActionTree, MutationTree } from 'vuex'
import axios from 'axios'
import { RootState } from '~/store'

export const state = () => ({
  totalPosts: 0,
  postsPerPage: 12,
  pages: {} as { [key:number]: any[] },
  currentPageNumber: 1,
  isFetchingList: false,
  hasError: false,
  blog: null as any
})

export type BlogState = ReturnType<typeof state>

export enum BlogActions {
  setTotalAmountOfPosts = 'blog/setTotalAmountOfPosts',
  fetchPostsByPage = 'blog/fetchPostsByPage',
  setCurrentPageNumber = 'blog/setCurrentPageNumber',
  setBlog = 'blog/setBlog'
}

export enum BlogGetters {
  blog = 'blog/blog',
  totalPosts = 'blog/totalPosts',
  isFetchingList = 'blog/isFetchingList',
  hasError = 'blog/hasError',
  currentPageNumber = 'blog/currentPageNumber',
  postsInCurrentPage = 'blog/postsInCurrentPage',
  postsPerPage = 'blog/postsPerPage'
}

export const actions: ActionTree<BlogState, RootState> = {
  setBlog ({ commit }, payload: any) {
    commit('SET_BLOG', payload)
  },
  setCurrentPageNumber ({ commit }, payload: number) {
    commit('SET_CURRENT_PAGE_NUMBER', payload)
  },
  setTotalAmountOfPosts ({ commit }, payload: number) {
    commit('SET_TOTAL_POST_AMOUNT', payload)
  },
  async fetchPostsByPage ({ state, commit }, pageNumber: number) {
    commit('SET_CURRENT_PAGE_NUMBER', pageNumber)
    if (state.pages[pageNumber] !== undefined) {
      return
    }
    commit('SET_IS_FETCHING_LIST', true)
    commit('SET_HAS_ERROR', false)

    await axios.get<any>('https://public-api.wordpress.com/wp/v2/sites/veke.blog/posts?page=' + pageNumber + '&per_page=' + state.postsPerPage)
      .then((response) => {
        commit('SET_TOTAL_POST_AMOUNT', +response.headers['x-wp-total'])
        commit('ADD_PAGE', { pageNumber, posts: response.data })
      })
      .catch((error:any) => {
        console.error(error)
        commit('SET_HAS_ERROR', true)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_LIST', false)
      })
  }
}

export const mutations: MutationTree<BlogState> = {
  SET_BLOG (state: BlogState, payload: any) {
    state.blog = payload
  },
  SET_CURRENT_PAGE_NUMBER (state: BlogState, payload: number) {
    state.currentPageNumber = payload
  },
  SET_TOTAL_POST_AMOUNT (state: BlogState, payload: number) {
    state.totalPosts = payload
  },
  SET_HAS_ERROR (state: BlogState, payload: boolean) {
    state.hasError = payload
  },
  SET_IS_FETCHING_LIST (state: BlogState, payload: boolean) {
    state.isFetchingList = payload
  },
  ADD_PAGE (state: BlogState, payload: { pageNumber: number, posts: any[] }) {
    state.pages = { ...state.pages, [payload.pageNumber]: payload.posts }
  }
}

export const getters: GetterTree<BlogState, RootState> = {
  totalPosts: state => state.totalPosts,
  currentPageNumber: state => state.currentPageNumber,
  isFetchingList: state => state.isFetchingList,
  hasError: state => state.hasError,
  postsInCurrentPage: state => state.pages[state.currentPageNumber],
  postsPerPage: state => state.postsPerPage
}
