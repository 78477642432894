
import { PhoneIcon, MailIcon, ClockIcon } from '~/components/veke-ui/icons'

export default {
  name: 'CustomerServiceInfo',
  components: {
    PhoneIcon, MailIcon, ClockIcon
  },
  props: {
    content: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      phone: this.content.fields.phone,
      email: this.content.fields.email,
      openingHours: this.content.fields.openingHours
    }
  },
  computed: {
    openingHoursString () {
      const hours = this.openingHours
      const workdays = (hours.workdays) ? `arkisin ${hours.workdays.open}–${hours.workdays.close}` : ''
      const saturday = (hours.saturday) ? `la ${hours.saturday.open}–${hours.saturday.close}` : ''
      const sunday = (hours.sunday) ? `su ${hours.sunday.open}–${hours.sunday.close}` : ''
      const weekends = (hours.weekends) ? `la-su ${hours.weekends.open}–${hours.weekends.close}` : ''
      return [workdays, weekends, saturday, sunday].filter(v => v !== '').join(', ')
    },
    tel () {
      return this.phone ? `tel:${this.phone.replace(/\s/g, '')}` : '#'
    }
  }
}
